import { Injectable } from '@angular/core';
import { Environment } from '../models/environment/environment.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  public get environment(): Environment {
    return environment;
  }
}
